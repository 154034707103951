import { Suspense, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
import { Navbar } from "./components";
import { Container } from "react-bootstrap";
import { Footer, Header } from "./containers";
import {
  RegisterScreen,
  LoginScreen,
  HomeScreen,
  TermsScreen,
  PrivacyScreen,
  AboutScreen,
  PrivacyCookiesScreen,
  ResetPasswordScreen,
  JobEditScreen,
  ResetPasswordConfirmScreen,
  JobScreen,
} from "./screens";
import ProfileScreen from "./screens/profile/ProfileScreen";
import RedirectToDefaultLanguage from "./RedirectToDefaultLanguage";
import { useTranslation } from "react-i18next";

function App() {
  const [codeCountry, setCodeCountry] = useState("fr");

  const { i18n } = useTranslation();

  useEffect(() => {
    const pathSegments = window.location.pathname.split("/");
    const langSegment = pathSegments[1]; // Assuming the language code is the first segment
    setCodeCountry(pathSegments[1]);
    if (["en", "fr", "ar"].includes(langSegment)) {
      i18n.changeLanguage(langSegment);
    }
  }, [i18n, codeCountry]);

  return (
    <div className="App page-container">
      <Suspense fallback={null}>
        <Router>
          <main className="main">
            <Navbar />
            <Container className="wrapper">
              {/* <Navbar /> */}
              {/* <Header /> */}
              <Routes>
                <Route path="/" element={<RedirectToDefaultLanguage />} />
                <Route element={<HomeScreen />} path={`/:codeCountry/`} />
                <Route path="/:id/" element={<HomeScreen />} />
                <Route
                  element={<JobScreen />}
                  path={`/:codeCountry/jobs/:jobId`}
                />
                <Route
                  element={<AboutScreen />}
                  path={`/:codeCountry/about/`}
                />

                <Route
                  element={<TermsScreen />}
                  path={`/:codeCountry/terms/`}
                />
                <Route
                  path={`/:codeCountry/privacy/`}
                  element={<PrivacyScreen />}
                />
                <Route
                  path={`/:codeCountry/cookies-policy/`}
                  element={<PrivacyCookiesScreen />}
                />

                <Route element={<PrivateRoutes />}>
                  <Route
                    path={`/:codeCountry/profile/`}
                    element={<ProfileScreen />}
                  />
                  <Route
                    path={`/:codeCountry/job/:id/edit`}
                    element={<JobEditScreen />}
                  />
                </Route>
                <Route
                  element={
                    <RegisterScreen location={undefined} history={undefined} />
                  }
                  path={`/:codeCountry/register/`}
                />
                <Route
                  element={<LoginScreen />}
                  path={`/:codeCountry/login/`}
                />
                <Route
                  element={<ResetPasswordScreen />}
                  path={`/:codeCountry/reset-password/`}
                />
                <Route
                  path={`/:codeCountry/password/reset/confirm/:uid/:token`}
                  element={<ResetPasswordConfirmScreen />}
                />
              </Routes>
            </Container>
            <Footer />
          </main>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
