import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { CreateMessage } from "../../components/MessageUtils";
import FormContainer from "../../components/FormContainer";
import { register } from "../../actions/userActions";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import "./register.css";
import i18n from "../../i18n";
function RegisterScreen(location: any) {
  const navigate = useNavigate();
  function useXTranslation() {
    const { t } = useTranslation();

    return { t: t<string> };
  }

  const { t } = useXTranslation();
  const dir = i18n.language === "ar" ? "rtl" : "ltr";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isRecruiter, setIsRecruiter] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Register.Name is required")),
    email: Yup.string()
      .email(t("Register.Enter a valid email"))
      .required(t("Register.Email is required")),
    password: Yup.string()
      .min(8, t("Register.Password must be at least 8 characters"))
      .required(t("Register.Password is required")),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), undefined],
        t("Register.Passwords must match")
      )
      .required(t("Register.Confirm Password is required")),
  });

  const dispatch = useDispatch();
  const codeLng = window.location.pathname.substring(1, 3) || "fr";

  const [isChecked, setIsChecked] = useState(false);

  const redirect = location.search
    ? location.search.split("=")[1]
    : `/${codeLng}/profile/`;

  const userRegister = useSelector((state: any) => state.userRegister);

  const { error, loading, userInfo } = userRegister;

  const errtMsg = CreateMessage("danger", error);
  const displayMsg = CreateMessage("danger", message);

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, userInfo, redirect, error]);

  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    isRecruiter: false,
  };

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    dispatch<any>(
      register(values.name, values.email, values.password, values.isRecruiter)
    );
    setSubmitting(false);
  };
  // const submitHandler = (e: any) => {
  //   e.preventDefault();
  //   if (password !== confirmPassword) {
  //     setMessage("Password do not match");
  //   } else {
  //     dispatch<any>(register(name, email, password, isRecruiter));
  //   }
  // };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    setIsRecruiter(true);
  };

  return (
    <div className="linkedin-register-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <label>
              <Field type="checkbox" name="isRecruiter" />{" "}
              {t("Register.isRecruiter")}
            </label>

            <Field
              type="text"
              name="name"
              placeholder={t("Register.Name")}
              className="linkedin-input"
              autoComplete="on"
            />
            <ErrorMessage
              name="name"
              render={(msg) => (
                <div className="error-message">{t("Error.NameRequired")}</div>
              )}
            />

            <Field
              type="email"
              name="email"
              placeholder={t("Register.Enter Email")}
              className="linkedin-input"
              autoComplete="on"
            />
            <ErrorMessage
              name="email"
              render={(msg) => (
                <div className="error-message">{t("Error.EmailRequired")}</div>
              )}
            />

            <Field
              type="password"
              name="password"
              placeholder={t("Register.Enter Password")}
              className="linkedin-input"
              autoComplete="on"
            />
            <ErrorMessage
              name="password"
              render={(msg) => (
                <div className="error-message">
                  {t("Error.PasswordRequired")}
                </div>
              )}
            />

            <Field
              type="password"
              name="confirmPassword"
              placeholder={t("Register.Confirm Password")}
              className="linkedin-input"
              autoComplete="on"
            />
            <ErrorMessage
              name="confirmPassword"
              render={(msg) => (
                <div className="error-message">
                  {t("Error.ConfirmPasswordRequired")}
                </div>
              )}
            />
            <p dir={dir} className="jobmaroc__register-form-body-agreement">
              {t("JobMaroc.By creating an account, you agree to the JobMaroc")}
              <Link to={`/${codeLng}/terms/`}>
                {" "}
                {t("JobMaroc.Terms of Service")}
              </Link>
              {", "}
              <Link to={`/${codeLng}/privacy/`}>
                {t("JobMaroc.Privacy Policy")}
              </Link>
              {t("JobMaroc., and ")}
              <Link to={`/${codeLng}/cookies-policy/`}>
                {t("JobMaroc.Cookie Policy")}
              </Link>
            </p>

            <button
              type="submit"
              disabled={isSubmitting}
              className="linkedin-button"
            >
              {t("Register.Sign Up")}
            </button>
          </Form>
        )}
      </Formik>
      <p className="jobmarok-register-form_login">
        {t("Register.Have an account?")}{" "}
        <Link to={`/${codeLng}/login/`}>{t("Login.Sign In")}</Link>
      </p>
      {/* <FormContainer>
        <h1 className="letterSpacing-ar"> {t("Register.Sign Up")}</h1>
        <br />
        {message && displayMsg}
        {error && errtMsg}
        {loading && <Loader />}
        <Form onSubmit={submitHandler} className="jobmaroc__register-container">
          <Form.Group
            controlId="switch"
            className="mb-3 jobmaroc__register-container_input"
          >
            <label className="switch">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />{" "}
              {t("Register.isRecruiter")}
            </label>
          </Form.Group>
          <Form.Group
            controlId="name"
            className="mb-3 jobmaroc__register-container_input"
          >
            <Form.Label>{t("Register.Name")}</Form.Label>
            <Form.Control
              required
              type="name"
              placeholder={t("Register.Name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group
            controlId="email"
            className="mb-3 jobmaroc__register-container_input"
          >
            <Form.Label>{t("Register.Email")} </Form.Label>
            <Form.Control
              required
              type="email"
              placeholder={t("Register.Enter Email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group
            controlId="password"
            className="mb-3 jobmaroc__register-container_input"
          >
            <Form.Label>{t("Register.Password")}</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder={t("Register.Enter Password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="on"
            ></Form.Control>
          </Form.Group>

          <Form.Group
            controlId="confirmPassword"
            className="mb-3 jobmaroc__register-container_input"
          >
            <Form.Label>{t("Register.Confirm Password")}</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder={t("Register.Confirm Password")}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              autoComplete="on"
            ></Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary">
            {t("Register.Sign Up")}
          </Button>
        </Form>
        <Row className="jobmaroc__register-form-body-agreement">
          <Col>
            {t("JobMaroc.By creating an account, you agree to the JobMaroc")}
            <Link to={`/${codeLng}/terms/`}>
              {" "}
              {t("JobMaroc.Terms of Service")}{" "}
            </Link>
            <Link to={`/${codeLng}/privacy/`}>
              {t("JobMaroc.Privacy Policy")}
            </Link>
            <Link to={`/${codeLng}/cookies-policy/`}>
              {t("JobMaroc.Cookie Policy")}
            </Link>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            {t("Register.Have an account?")}{" "}
            <Link to={`/${codeLng}/login/`}>{t("Login.Sign In")}</Link>
          </Col>
        </Row>
      </FormContainer> */}
    </div>
  );
}

export default RegisterScreen;
