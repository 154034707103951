import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import jobmarok_tb from "../../assets/jobmarok_tb.png";
import "./navbar.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import i18n from "../../i18n";
import { logout } from "../../actions/userActions";
import Dropdown from "react-bootstrap/Dropdown";
import { LinkContainer } from "react-router-bootstrap";
import { FaUser } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { MdOutlineLogout } from "react-icons/md";
import { useLanguage } from "../../LanguageContext";

const Menu = () => (
  <>
    <p>
      <Link to={`/`}> {i18n.t("Navbar.Home")}</Link>
    </p>
    <p>
      <Link to={`/`}> {i18n.t("Navbar.Jobs")}</Link>
    </p>
  </>
);

const Navbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const userLogin = useSelector((state: any) => state.userLogin);
  const { userInfo } = userLogin;
  const codeLng = window.location.pathname.substring(1, 3) || "fr";
  const dispatch = useDispatch();

  const { language, setLanguage } = useLanguage();

  const handleChangeLanguage = (selectedLanguage: any) => {
    setLanguage(selectedLanguage);

    const pathname = location.pathname;
    const newPathname = pathname.replace(/^\/[a-z]{2}/, `/${selectedLanguage}`);
    i18n.changeLanguage(selectedLanguage);
    navigate(newPathname);
  };

  const logoutHandler = () => {
    dispatch<any>(logout());
  };
  const dir = i18n.language === "ar" ? "rtl" : "ltr";

  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <header dir={dir} className="jobmarok__header">
      <div className="jobmarok__navbar">
        <div className="jobmarok__navbar-links_logo">
          <Link to={`/${codeLng}/`}>
            <img src={jobmarok_tb} alt="jobmarok" />
          </Link>
        </div>

        <div className="jobmarok__navbar-links">
          <div className="jobmarok__navbar-links_container">
            <select
              className="custom-select"
              value={i18n.language}
              onChange={(e) => handleChangeLanguage(e.target.value)}
            >
              <option value="fr">Fr</option>
              <option value="en">En</option>
              <option value="ar">Ar</option>
            </select>
            <Menu />
          </div>

          {userInfo ? (
            <div className="jobmarok__navbar-sign">
              <Dropdown>
                <Dropdown.Toggle
                  className="select"
                  variant="primary"
                  id="dropdown-basic"
                >
                  <FaUser />
                </Dropdown.Toggle>
                <Dropdown.Menu className="jobmarok__navbar-sign_options-account">
                  <LinkContainer to={`/${codeLng}/profile/`}>
                    <Dropdown.Item className="jobmarok__navbar-sign_options-account_title">
                      {" "}
                      <p className="jobmarok__navbar-sign_options-account_title-name">
                        {userInfo.name}
                      </p>
                      <sup>{userInfo.email}</sup>
                    </Dropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={`/${codeLng}/profile/`}>
                    <Dropdown.Item className="jobmarok__navbar-sign_options-account_profile">
                      <FiUser />
                      {t("Profile.Profile")}
                    </Dropdown.Item>
                  </LinkContainer>
                  <Dropdown.Item
                    className="jobmarok__navbar-sign_options-account_logout"
                    onClick={logoutHandler}
                  >
                    <MdOutlineLogout />
                    {t("Login.Logout")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <div className="jobmarok__navbar-sign">
              <p>
                <Link to={`/${codeLng}/login/`}>{t("Login.Sign In")}</Link>
              </p>
              <button type="button">
                <Link to={`/${codeLng}/register/`}>
                  {t("Register.Sign Up")}
                </Link>
              </button>
            </div>
          )}
        </div>
        <div className="jobmarok__navbar-menu">
          {toggleMenu ? (
            <RiCloseLine
              color="#050505"
              size={27}
              onClick={() => setToggleMenu(false)}
            />
          ) : (
            <RiMenu3Line
              color="#050505"
              size={27}
              onClick={() => setToggleMenu(true)}
            />
          )}
          {toggleMenu && (
            <div
              dir="ltr"
              className={`jobmarok__navbar-menu_container scale-up-center ${
                dir === "rtl" ? "rtlMenu" : "ltrMenu"
              }`}
            >
              <div className="jobmarok__navbar-menu_container-links">
                <select
                  className="custom-select"
                  value={i18n.language}
                  onChange={(e) => handleChangeLanguage(e.target.value)}
                >
                  <option value="fr">Fr</option>
                  <option value="en">En</option>
                  <option value="ar">Ar</option>
                </select>
                <Menu />
              </div>
              {userInfo ? (
                <div className="jobmarok__navbar-menu_container-links-sign">
                  <LinkContainer
                    className="jobmarok__navbar-menu_container-links-sign_body"
                    to={`/${codeLng}/profile/`}
                  >
                    <Dropdown.Item>
                      <FiUser />
                      {t("Profile.Profile")}
                    </Dropdown.Item>
                  </LinkContainer>
                  <button type="button" onClick={logoutHandler}>
                    {t("Logout")}
                  </button>
                </div>
              ) : (
                <div className="jobmarok__navbar-menu_container-links-sign">
                  <p>
                    <Link to={`/${codeLng}/login/`}>{t("Login.Sign In")}</Link>
                  </p>
                  <button type="button">
                    <Link to={`/${codeLng}/register/`}>
                      {t("Register.Sign Up")}
                    </Link>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
