import { HelmetProvider, Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import "./job.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifyErr, notifySuccess } from "../../components/MessageUtils";
import { Job } from "../../types";
import { useState } from "react";

interface JobDetailsProps {
  selectedJob: null | Job;
}

const JobDetails: React.FC<JobDetailsProps> = ({ selectedJob }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userLogin = useSelector((state: any) => state.userLogin);
  const { userInfo } = userLogin;

  const codeLng = window.location.pathname.substring(1, 3) || "fr";

  const handleApply = () => {
    navigate(`/${codeLng}/login/`);
  };

  const handleAddJobApplication = async (selectedJob: Job) => {
    if (userInfo) {
      try {
        const newJobApplication = {
          date_applied: new Date().toISOString().split("T")[0],
          status: "applied",
          user: userInfo.id,
          job: selectedJob.id,
        };
        const axiosConfig = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
          params: { user: userInfo },
        };

        await axios.post(
          `/api/jobs/job-applications`,
          newJobApplication,
          axiosConfig
        );

        notifySuccess(t("Job application added successfully!"));
      } catch (error) {
        console.error("Error adding job application:", error);
        notifyErr(t("Error adding job application"));
      }
    } else {
      navigate(`/${codeLng}/login`);
    }
  };

  return (
    <div>
      <ToastContainer />
      {selectedJob && (
        <div className="job-card-details">
          <HelmetProvider>
            <Helmet>
              <meta
                property="og:title"
                content={selectedJob.title}
                data-react-helmet="true"
              />
              <meta
                property="og:description"
                content={selectedJob.description}
                data-react-helmet="true"
              />

              <meta
                property="og:url"
                content={`https://www.jobmarok.com/${codeLng}/jobs/${selectedJob.id}`}
                data-react-helmet="true"
              />

              <meta
                property="og:image"
                content={
                  typeof selectedJob.image === "string" ? selectedJob.image : ""
                }
              />
              <meta
                property="og:image:url"
                content={
                  typeof selectedJob.image === "string" ? selectedJob.image : ""
                }
              />
              <meta property="og:image:width" content="400" />
              <meta property="og:image:height" content="300" />
              <meta property="og:image:alt" content={selectedJob.title} />

              <meta name="twitter:title" content={selectedJob.title} />
              <meta
                name="twitter:description"
                content={selectedJob.description}
              />
              <meta
                name="twitter:image"
                content={
                  typeof selectedJob.image === "string" ? selectedJob.image : ""
                }
              />
              <meta name="twitter:image:alt" content={selectedJob.title} />
              <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
          </HelmetProvider>
          {userInfo && (
            <button
              className="jobMarok__jobDetails-addToMyJob"
              onClick={() => handleAddJobApplication(selectedJob)}
              style={{
                color: "#0a66c2",
                padding: "10px 20px",
                border: "1px solid #0a66c2",
                borderRadius: "16px",
                cursor: "pointer",
                textTransform: "none",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              {t("Job Details.Add To my Jobs")}
            </button>
          )}
          <div className="job-card-info">
            <div className="jobMarok__job-details_header">
              <div className="jobMarok__job-details_header-title">
                <h2 className="job-card-title">{selectedJob?.title}</h2>
                <p className="job-card-company">
                  {selectedJob?.company} - {selectedJob?.location}
                </p>
              </div>
              <div className="jobMarok__job-details_header-apply">
                {userInfo ? (
                  <>
                    {selectedJob?.phone &&
                      selectedJob?.phone != "000000000" && (
                        <p className="reduced-description">
                          {t("Job.Phone")}: {selectedJob?.phone}
                        </p>
                      )}
                    {selectedJob.email &&
                      selectedJob.email != "Sample Email" && (
                        <p className="reduced-description">
                          {t("Job.Email")}
                          {": "}
                        </p>
                      )}
                  </>
                ) : (
                  <>
                    {" "}
                    <button
                      className="jobMarok__job-details_apply-button"
                      onClick={handleApply}
                      style={{
                        backgroundColor: "#007bff",
                        color: "#fff",
                        padding: "10px 20px",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        textTransform: "none",
                      }}
                    >
                      {t("Job.Apply Now")}
                    </button>
                  </>
                )}
              </div>
            </div>
            <hr />
            <h2 className="jobMarok__job-details">{t("Job.details")}</h2>

            {selectedJob?.type && selectedJob.type !== "Sample Type" && (
              <p className="reduced-description">
                {t("Job.Job Type")} {selectedJob.type}
              </p>
            )}

            <div
              className="job-description-scroll"
              dangerouslySetInnerHTML={{
                __html: selectedJob?.description || "",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default JobDetails;
