import { useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Table, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../components";
import { GrFormEdit, GrFormTrash } from "react-icons/gr";
import { CreateMessage } from "../../components/MessageUtils";
import { listMyJobs, deleteJob } from "../../actions/jobActions";
import PaginateMyJobs from "./PaginateMyJobs";
import "./listMyJobs.css";
function ListMyJobs(keyword: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const jobListMy = useSelector((state: any) => state.jobListMy);
  const {
    loading: loadingJobs,
    error: errorJobs,
    jobs,
    page,
    pages,
  } = jobListMy;

  const codeLng = window.location.pathname.substring(1, 3) || "fr";

  const userLogin = useSelector((state: any) => state.userLogin);
  const { userInfo } = userLogin;
  const [jobId, setJobId] = useState({});
  const msgConfirm = "Are you sure, you want to delete this job?";
  const [show, setShow] = useState(false);

  const errtMsg = CreateMessage("danger", errorJobs);

  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch<any>(listMyJobs(keyword));
  }, [dispatch, userInfo, keyword]);

  const deleteHandler = (id: any) => {
    dispatch<any>(deleteJob(id));
    dispatch<any>(listMyJobs(keyword));
    setShow(false);
  };

  return (
    <div>
      {loadingJobs ? (
        <Loader />
      ) : errorJobs ? (
        errtMsg
      ) : (
        <div>
          <h2>{t("Job.My Jobs")}</h2> <br />
          <Table
            striped
            bordered
            hover
            responsive
            className="jobMarok__ListMyJobs"
          >
            <thead>
              <tr>
                <th>{t("Job.Title")}</th>
                <th>{t("Job.Company")}</th>
                <th>{t("Job.Location")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {jobs.map((job: any) => (
                <tr key={job.id}>
                  <td>{job.title}</td>
                  <td>{job.company}</td>
                  <td>{job.location}</td>
                  <td>
                    <LinkContainer to={`/${codeLng}/job/${job.id}/edit`}>
                      <Button variant="light" className="btn-sm">
                        <GrFormEdit className="search-container_location-icon" />
                      </Button>
                    </LinkContainer>

                    {
                      <GrFormTrash
                        onClick={() => {
                          setShow(true);
                          setJobId(job.id);
                        }}
                        className="search-container_location-icon"
                        id="trashIcon"
                      />
                    }
                  </td>
                </tr>
              ))}

              <Modal className="dirct-ltr" show={show} onHide={handleClose}>
                <Modal.Header>
                  <Modal.Title className="text-ar">
                    {t("Confirm Delete")}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>{t(msgConfirm)}</Modal.Body>
                <Modal.Footer>
                  <Button onClick={handleClose} variant="light">
                    {t("Close")}
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => deleteHandler(jobId)}
                  >
                    {t("Save Changes")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </tbody>
          </Table>
          {<PaginateMyJobs pages={pages} page={page} />}
        </div>
      )}
    </div>
  );
}

export default ListMyJobs;
