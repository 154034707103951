import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import FormContainer from "../components/FormContainer";
import { resetPassword } from "../actions/userActions";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { notifyErr, notifySuccess } from "../components/MessageUtils";

function ResetPasswordScreen() {
  const { t } = useTranslation();
  const codeLng = window.location.pathname.substring(1, 3) || "fr";

  const navigate = useNavigate();

  const [requestSent, setRequestSent] = useState(false);
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (requestSent) {
      notifySuccess(
        t("Messages.Password reset link sent! Please check your email.")
      );
      setTimeout(() => {
        navigate(`/${codeLng}/`);
      }, 5000);
    }
  }, [navigate, requestSent]);

  const submitHandler = (e: any) => {
    e.preventDefault();

    dispatch<any>(resetPassword(email));

    setRequestSent(true);
  };

  return (
    <>
      <ToastContainer />
      <FormContainer>
        <h1 className="letterSpacing-ar">
          {" "}
          {t("Login.Request Password Reset")}
        </h1>
        {/* {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />} */}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="email" className="mb-3">
            <Form.Label>{t("Login.Email")}</Form.Label>
            <Form.Control
              type="email"
              // placeholder={t("Login.Enter Email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button className="btn btn-primary" type="submit">
            {t("Login.Reset Password")}
          </Button>
        </Form>
      </FormContainer>
    </>
  );
}

export default ResetPasswordScreen;
