import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { PaginateJobs } from "../../components";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useParams, Link } from "react-router-dom";
import "./home.css";
import SearchBox from "../../components/Form/SearchBox";
import { listJobs } from "../../actions/jobActions";
import { Job, JobDetails } from "../../components";
import axios from "axios";
import { Job as JobeType } from "../../types";
import i18n from "../../i18n";

function HomeScreen() {
  const userLogin = useSelector((state: any) => state.userLogin);
  const { userInfo } = userLogin;
  const codeLng = i18n.language;
  const dispatch = useDispatch();
  const jobList = useSelector((state: any) => state.jobList);
  const { error, loading, jobs, page, pages } = jobList;
  const [selectedJob, setSelectedJob] = useState<null | JobeType>(null);
  const firstJob: JobeType | undefined = jobs?.length > 0 ? jobs[0] : [];

  const { jobId } = useParams<{ jobId: string }>();

  const searchParams = new URLSearchParams(window.location.search);
  const keyword = searchParams.get("keyword");
  const jobLocation = searchParams.get("jobLocation");
  const newPage = searchParams.get("page");

  useEffect(() => {
    dispatch<any>(listJobs(keyword, jobLocation, newPage));
    if (jobId) {
      fetchJobDetails();
    }
  }, [dispatch, keyword, newPage, jobId, jobLocation]);

  const initSelectedJob = () => {
    setSelectedJob(null);
  };

  const fetchJobDetails = async () => {
    try {
      const axiosConfig = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
        params: {},
      };
      const response = await axios.get<JobeType>(
        `/api/jobs/${jobId}/`,
        axiosConfig
      );

      setSelectedJob(response.data);
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
  };

  const handleJobClick = (job: JobeType) => {
    setSelectedJob(job);
  };

  const renderJobDetailsLink = (job: JobeType) => {
    if (window.innerWidth <= 768) {
      return (
        <Link
          to={`jobs/${job.id}`}
          className={
            selectedJob === job
              ? "showSelectedJob btn-job unsetColorLink"
              : "btn-job unsetColorLink"
          }
        >
          <Job job={job} />
        </Link>
      );
    } else {
      return (
        <button
          onClick={() => handleJobClick(job)}
          className={
            selectedJob === job ? "showSelectedJob btn-job" : "btn-job"
          }
        >
          <Job job={job} />
        </button>
      );
    }
  };
  const isEmpty = (obj: any) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  return (
    <div>
      {/* <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.jobmarok.com/fr/" />
      </Helmet> */}

      <SearchBox initSelectedJob={initSelectedJob} />
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>
          <Row className="bayan__container">
            <Col xs={12} md={5} className="bayan__container-book_container">
              {jobs && jobs.length > 0 ? (
                jobs.map((job: any, index: any) => (
                  <div key={index}>{renderJobDetailsLink(job)}</div>
                ))
              ) : (
                <p>No jobs available</p>
              )}
              <div className="books-list"></div>
              {
                <PaginateJobs
                  pages={pages}
                  page={page}
                  keyword={keyword}
                  jobLocation={jobLocation}
                />
              }
            </Col>
            <Col xs={12} md={7} className="bayan__container-rss">
              {selectedJob ? (
                <JobDetails selectedJob={selectedJob} />
              ) : firstJob && !isEmpty(firstJob) ? (
                <JobDetails selectedJob={firstJob} />
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default HomeScreen;
