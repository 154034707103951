import axios from "axios";
import {
  JOB_CREATE_REQUEST,
  JOB_CREATE_SECCESS,
  JOB_CREATE_FAIL,
  JOB_UPDATE_REQUEST,
  JOB_UPDATE_SECCESS,
  JOB_UPDATE_FAIL,
  JOB_DETAILS_REQUEST,
  JOB_DETAILS_SECCESS,
  JOB_DETAILS_FAIL,
  JOB_DELETE_REQUEST,
  JOB_DELETE_SECCESS,
  JOB_DELETE_FAIL,
  JOB_LIST_REQUEST,
  JOB_LIST_SECCESS,
  JOB_LIST_FAIL,
  JOB_LIST_MY_REQUEST,
  JOB_LIST_MY_SUCCESS,
  JOB_LIST_MY_FAIL,
} from "../constants/jobConstants";

export const listJobs =
  (keyword: string | null, jobLocation: string | null, page: string | null) =>
  async (dispatch: any) => {
    try {
      dispatch({ type: JOB_LIST_REQUEST });

      // const { data } = await axios.get(`/api/jobs/${keyword}`);
      const { data } = await axios.get(`/api/jobs/`, {
        params: { keyword, jobLocation, page },
      });

      dispatch({
        type: JOB_LIST_SECCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: JOB_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const jobDetails = (id: any) => async (dispatch: any) => {
  try {
    dispatch({ type: JOB_DETAILS_REQUEST });

    const { data } = await axios.get("/api/jobs/" + id);

    dispatch({
      type: JOB_DETAILS_SECCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: JOB_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listJobDetails = (id: any) => async (dispatch: any) => {
  try {
    dispatch({ type: JOB_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/jobs/${id.id}/`);

    dispatch({
      type: JOB_DETAILS_SECCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: JOB_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createJob = () => async (dispatch: any, getState: any) => {
  try {
    dispatch({
      type: JOB_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/jobs/create/`, {}, config);

    dispatch({
      type: JOB_CREATE_SECCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: JOB_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateJob = (job: any) => async (dispatch: any, getState: any) => {
  try {
    dispatch({
      type: JOB_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/jobs/${job.id}/update/`,
      job,
      config
    );

    dispatch({
      type: JOB_UPDATE_SECCESS,
      payload: data,
    });

    dispatch({
      type: JOB_DETAILS_SECCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: JOB_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

interface ParamsKeyword {
  keyword: string;
  page: number;
}
export const listMyJobs =
  (query: ParamsKeyword) => async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: JOB_LIST_MY_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const axiosConfig = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
        params: {
          keyword: query.keyword,
          page: query.page,
        },
      };

      const { data } = await axios.get(`/api/jobs/userJobs/`, axiosConfig);

      dispatch({
        type: JOB_LIST_MY_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: JOB_LIST_MY_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const deleteJob = (id: any) => async (dispatch: any, getState: any) => {
  try {
    dispatch({
      type: JOB_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`/api/jobs/${id}/delete/`, config);

    dispatch({
      type: JOB_DELETE_SECCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: JOB_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
