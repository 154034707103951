import React from "react";
import jobmarok_tb from "../../assets/jobmarok_tb.png";
import "./footer.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import i18n from "../../i18n";

const Footer = () => {
  const { t } = useTranslation();
  // const codeLng = window.location.pathname.substring(1, 3) || "fr";
  const codeLng = i18n.language;

  // const dir = codeLng === "ar" ? "rtl" : "ltr";
  const dir = i18n.language === "ar" ? "rtl" : "ltr";

  return (
    <div className="jobmarok__footer section__padding" dir={dir}>
      <div className="jobmarok__footer-links">
        <div className="jobmarok__footer-links_logo">
          <img src={jobmarok_tb} alt="jobmarok_logo" />
        </div>
        <div className="jobmarok__footer-links_div">
          <h4>{t("JobMaroc.Company")}</h4>
          <p>
            <Link to={`${codeLng}/about`}>{t("JobMaroc.About us")}</Link>
          </p>
          <p>
            <Link to={`${codeLng}/terms`}>
              {t("JobMaroc.Terms of Service")}
            </Link>{" "}
          </p>
          <p>
            <Link to={`${codeLng}/privacy`}>
              {t("JobMaroc.Privacy Policy")}
            </Link>
          </p>
        </div>
        <div className="jobmarok__footer-links_div">
          <h4>{t("JobMaroc.Links")}</h4>
          <p>
            <a
              className="responsiveSiteFooter__socialLink"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.facebook.com/groups/599887746741446"
            >
              <FaFacebook size="1.5em" />{" "}
            </a>
          </p>
          <p>
            {" "}
            <Link to={`${codeLng}/profile/`}>{t("Job.Post a Job")}</Link>
          </p>
        </div>

        <div className="jobmarok__footer-links_div" id="contact">
          <h4>{t("JobMaroc.Get in touch")}</h4>
          <p>{t("JobMaroc.Rabat, Morocco")}</p>
          <p>{t("JobMaroc.Contact")}: jobmarok7@gmail.com</p>
        </div>
      </div>
      <div className="jobmarok__footer-copyright">
        <p>
          {t("JobMaroc.Copyright © 2023 JobMaroc Inc. All rights reserved.")}
        </p>
      </div>
    </div>
  );
};

export default Footer;
