import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

interface Props {
  pages: any;
  page: any;
}

function PaginateMyJobs({ pages, page }: Props, { isAdmin = false }) {
  const searchParams = new URLSearchParams(window.location.search);
  const keyword = searchParams.get("keyword");

  return (
    <>
      {pages > 1 && (
        <Pagination>
          {[...Array(pages).keys()].map((x) => (
            <LinkContainer
              key={x + 1}
              to={{
                pathname: ``,
                search: `keyword=${keyword}&page=${x + 1}`,
              }}
            >
              <Pagination.Item active={x + 1 === page} id="pagination">
                {x + 1}
              </Pagination.Item>
            </LinkContainer>
          ))}
        </Pagination>
      )}
    </>
  );
}

export default PaginateMyJobs;
