import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";

interface Props {
  pages: any;
  page: any;
  keyword: any;
  jobLocation: any;
}

function PaginateJobs({ pages, page, keyword, jobLocation }: Props) {
  const searchParams = new URLSearchParams(window.location.search);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return (
    <>
      {pages > 1 && (
        <Pagination>
          {Array.from({ length: pages }, (_, x) => (
            <LinkContainer
              key={x + 1}
              to={{
                pathname: ``,
                search: `keyword=${keyword ? keyword : ""}&jobLocation=${
                  jobLocation ? jobLocation : ""
                }&page=${x + 1}`,
              }}
            >
              <Pagination.Item key={x + 1} active={x + 1 === page}>
                {x + 1}
              </Pagination.Item>
            </LinkContainer>
          ))}
        </Pagination>
      )}
    </>
  );
}

export default PaginateJobs;
