import { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import FormContainer from "../../components/FormContainer";
import { listJobDetails, updateJob } from "../../actions/jobActions";
import { JOB_UPDATE_RESET } from "../../constants/jobConstants";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Job } from "../../types";
import axios from "axios";
import { notifyErr, notifySuccess } from "../../components/MessageUtils";
import "./profile.css";

function JobEditScreen() {
  const { t } = useTranslation();
  const jobId = useParams();
  const [imageName, setImageName] = useState("");
  const [jobEdited, setJobEdited] = useState<Job>({
    id: Number(jobId.id),
    title: "",
    location: "",
    company: "",
    image: null,
    type: "",
    description: "",
    phone: "",
    email: "",
    salary: "",
    experience: "",
  });

  const codeLng = window.location.pathname.substring(1, 3) || "fr";

  const dispatch = useDispatch();

  const userLogin = useSelector((state: any) => state.userLogin);
  const { userInfo } = userLogin;

  const navigate = useNavigate();

  const jobDetails = useSelector((state: any) => state.jobDetails);
  const { error, loading, job } = jobDetails;

  const jobUpdate = useSelector((state: any) => state.jobUpdate);
  const {
    error: errorUpdate,
    loading: loadingUpdate,
    success: successUpdate,
  } = jobUpdate;

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    if (successUpdate) {
      dispatch({ type: JOB_UPDATE_RESET });
      navigate(`/${codeLng}/profile/`);
    } else {
      if (!job.title || Number(job.id) !== Number(jobId.id)) {
        dispatch<any>(listJobDetails(jobId));
      } else {
        setJobEdited(job);
        setImageName(job.image);
      }
    }
  }, [
    dispatch,
    job,
    jobId,
    successUpdate,
    userInfo,
    navigate,
    codeLng,
    imageName,
  ]);

  const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", jobEdited.title);
    formData.append("company", jobEdited.company);
    formData.append("image", jobEdited.image || "");
    formData.append(
      "imageName",
      imageName.split("/").pop()?.split("?")[0] || ""
    );
    formData.append("type", jobEdited.type);
    formData.append("experience", jobEdited.experience);
    formData.append("salary", jobEdited.salary);
    formData.append("phone", jobEdited.phone);
    formData.append("email", jobEdited.email);
    formData.append("description", jobEdited.description);
    formData.append("location", jobEdited.location);

    try {
      await axios.put(`/api/jobs/${jobId.id}/update/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      });

      if (!jobEdited.title || jobEdited.title === "Sample Title") {
        notifyErr(t("Please enter the job title!"));
      } else if (!jobEdited.company || jobEdited.company === "Sample Company") {
        notifyErr(t("Please enter the job Company!"));
      } else if (
        !jobEdited.description ||
        jobEdited.description === "<p>Sample Description</p>"
      ) {
        notifyErr(t("Please enter the job Description!"));
      } else {
        dispatch<any>(updateJob(jobEdited));
        notifySuccess(t("Job added successfully!"));
      }
    } catch (error) {
      notifyErr(t("Error adding job!"));
      console.error("Error adding job:", error);
    }
  };

  const handleEditorChange = (value: string) => {
    setJobEdited((prevJob) => ({
      ...prevJob,
      description: value,
    }));
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setJobEdited({ ...jobEdited, image: file });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setJobEdited({ ...jobEdited, [name]: value });
  };

  return (
    <div>
      <ToastContainer />
      <FormContainer>
        <h1 className="text-ar">{t("Job.Post a Job")}</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="title" className="mb-3">
              <Form.Label>{t("Job.Title")}</Form.Label>
              <Form.Control
                className="inputFontSize "
                type="text"
                name="title"
                value={
                  jobEdited.title === "Sample Title"
                    ? t("Job." + jobEdited.title) || ""
                    : jobEdited.title || ""
                }
                placeholder="Enter your email"
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="type" className="mb-3">
              <Form.Label>{t("Job.Type")}</Form.Label>
              <Form.Control
                className="inputFontSize "
                type="text"
                name="type"
                value={
                  jobEdited.type === "Sample Type"
                    ? t("Job." + jobEdited.type) || ""
                    : jobEdited.type || ""
                }
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="company" className="mb-3">
              <Form.Label>{t("Job.Company")}</Form.Label>
              <Form.Control
                className="inputFontSize "
                type="text"
                name="company"
                value={
                  jobEdited.company === "Sample Company"
                    ? t("Job." + jobEdited.company) || ""
                    : jobEdited.company || ""
                }
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="image" className="mb-3">
              <Form.Label>{t("Job.Image")}</Form.Label>
              <Form.Control
                className="inputFontSize "
                type="file"
                name="image"
                onChange={handleImageChange}
              />
            </Form.Group>
            {imageName && (
              <span className="selected-file-name">
                {t("Selected File:")}{" "}
                {imageName.split("/").pop()?.split("?")[0]}
              </span>
            )}

            <Form.Group controlId="location" className="mb-3">
              <Form.Label>{t("Job.Location")}</Form.Label>
              <Form.Control
                className="inputFontSize "
                type="text"
                name="location"
                placeholder={t("Enter Location") || ""}
                value={
                  jobEdited.location === "Sample Location"
                    ? t("Job." + jobEdited.location) || ""
                    : jobEdited.location || ""
                }
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="salary" className="mb-3">
              <Form.Label>{t("Job.Salary")}</Form.Label>
              <Form.Control
                className="inputFontSize "
                type="text"
                name="salary"
                placeholder={t("Enter Salary") || ""}
                value={jobEdited.salary}
                onChange={handleInputChange}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="experience" className="mb-3">
              <Form.Label>{t("Job.Experience")}</Form.Label>
              <Form.Control
                className="inputFontSize "
                type="text"
                name="experience"
                placeholder={t("Enter Experience") || ""}
                value={
                  jobEdited.experience === "Sample Experience"
                    ? t("Job." + jobEdited.experience) || ""
                    : jobEdited.experience || ""
                }
                onChange={handleInputChange}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="phone" className="mb-3">
              <Form.Label>{t("Job.Phone")}</Form.Label>
              <Form.Control
                className="inputFontSize "
                type="text"
                name="phone"
                placeholder={t("Enter Phone") || ""}
                value={jobEdited.phone}
                onChange={handleInputChange}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="email" className="mb-3">
              <Form.Label>{t("Job.Email")}</Form.Label>
              <Form.Control
                className="inputFontSize "
                type="text"
                name="email"
                placeholder={t("Enter Email") || ""}
                value={
                  jobEdited.email === "Sample Email"
                    ? t("Job." + jobEdited.email) || ""
                    : jobEdited.email || ""
                }
                onChange={handleInputChange}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="description" className="mb-3">
              <Form.Label>{t("Job.Description")}</Form.Label>

              <ReactQuill
                className="inputFontSize "
                value={
                  jobEdited.description === "Sample Description"
                    ? t("Job." + jobEdited.description) || ""
                    : jobEdited.description || ""
                }
                onChange={handleEditorChange}
              />
            </Form.Group>

            <div className="d-grid gap-2 ">
              <Button type="submit" variant="primary" className="inputFontSize">
                {t("Update Job")}
              </Button>
            </div>
          </Form>
        )}
      </FormContainer>
    </div>
  );
}

export default JobEditScreen;
