import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_PASSWORD_RESET_CONFIRM_REQUEST,
  USER_PASSWORD_RESET_CONFIRM_FAIL,
  USER_PASSWORD_RESET_CONFIRM_SUCCESS,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_FAIL,
  USER_PASSWORD_RESET_SUCCESS,
} from "../constants/userConstants";

import { JOB_LIST_MY_RESET } from "../constants/jobConstants";
import axios from "axios";

export const login = (email: any, password: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    const { data } = await axios.post(
      "/api/users/login/",
      { email: email, password: password },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error: any) {
    // const err = error as AxiosError
    let errMsg = "" + error.response.data.detail;

    if (error.response && error.response.data && error.response.data.detail) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: errMsg,
      });
    } else {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: "An unknown error occurred.",
      });
    }
    // dispatch({
    //   type: USER_LOGIN_FAIL,
    //   payload:
    //     error.response && error.response.data.detail
    //       ? error.response.data.detail
    //       : error.message,
    // });
  }
};

export const resetPassword = (email: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_PASSWORD_RESET_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/auth/users/reset_password/",
      { email },
      config
    );

    dispatch({
      type: USER_PASSWORD_RESET_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_PASSWORD_RESET_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const resetPasswordConfirm =
  (uid: any, token: any, new_password: any, re_new_password: any) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: USER_PASSWORD_RESET_CONFIRM_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      // const axios = require("axios");
      const { data } = await axios.post(
        "/auth/users/reset_password_confirm/",
        { uid, token, new_password, re_new_password },
        config
      );
      dispatch({
        type: USER_PASSWORD_RESET_CONFIRM_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: USER_PASSWORD_RESET_CONFIRM_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const logout = () => (dispatch: any) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: JOB_LIST_MY_RESET });
  dispatch({ type: USER_LIST_RESET });
};

export const register =
  (name: any, email: any, password: any, isRecruiter: boolean) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      // const axios = require("axios");
      const { data } = await axios.post(
        "/api/users/register/",
        {
          name: name,
          email: email,
          password: password,
          isRecruiter: isRecruiter,
        },
        config
      );

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error: any) {
      let errMsg = "" + error.response.data.error;
      if (error.response && error.response.data && error.response.data.error) {
        dispatch({
          type: USER_REGISTER_FAIL,
          payload: errMsg,
        });
      } else {
        dispatch({
          type: USER_REGISTER_FAIL,
          payload: "An unknown error occurred.",
        });
      }
      // dispatch({
      //   type: USER_REGISTER_FAIL,
      //   payload:
      //     error.response && error.response.data.error
      //       ? error.response.data.error
      //       : error.message,
      // });
    }
  };

export const getUserDetails =
  (id: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: USER_DETAILS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/users/profile/`, config);

      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: USER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const updateUserProfile =
  (user: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: USER_UPDATE_PROFILE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // const axios = require("axios");
      const { data } = await axios.put(
        `/api/users/profile/update/`,
        user,
        config
      );

      dispatch({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error: any) {
      dispatch({
        type: USER_UPDATE_PROFILE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const listUsers = () => async (dispatch: any, getState: any) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const axios = require("axios");
    const { data } = await axios.get(`/api/users/`, config);

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteUser = (id: any) => async (dispatch: any, getState: any) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const axios = require("axios");
    const { data } = await axios.delete(`/api/users/delete/${id}/`, config);

    dispatch({
      type: USER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateUser =
  (user: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: USER_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const axios = require("axios");
      const { data } = await axios.put(
        `/api/users/update/${user._id}/`,
        user,
        config
      );

      dispatch({
        type: USER_UPDATE_SUCCESS,
      });

      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
