import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { JobDetails } from "../../components";
import { Job as JobeType } from "../../types";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { notifyErr, notifySuccess } from "../../components/MessageUtils";

function JobScreen() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [job, setJob] = useState<null | JobeType>(null);

  const { jobId } = useParams();

  const navigate = useNavigate();
  const userLogin = useSelector((state: any) => state.userLogin);
  const { userInfo } = userLogin;

  const codeLng = window.location.pathname.substring(1, 3) || "fr";

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await axios.get(`/api/jobs/${jobId}/`);
        setJob(response.data);
        setLoading(false);
      } catch (error) {
        setError("Error fetching job details");
        setLoading(false);
      }
    };

    fetchJob();
  }, [jobId]);

  const handleApply = () => {
    navigate(`/${codeLng}/login/`);
  };

  const handleAddJobApplication = async (selectedJob: JobeType) => {
    if (userInfo) {
      try {
        const newJobApplication = {
          date_applied: new Date().toISOString().split("T")[0],
          status: "applied",
          user: userInfo.id,
          job: selectedJob.id,
        };
        const axiosConfig = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
          params: { user: userInfo },
        };

        await axios.post(
          `/api/jobs/job-applications`,
          newJobApplication,
          axiosConfig
        );

        notifySuccess(t("Job application added successfully!"));
      } catch (error) {
        console.error("Error adding job application:", error);
        notifyErr(t("Error adding job application"));
      }
    } else {
      navigate(`/${codeLng}/login`);
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>
          <h1>{t("Job.details")}</h1>
          <div>
            <ToastContainer />
            {job && (
              <div className="job-card-details">
                <HelmetProvider>
                  <Helmet>
                    <meta
                      property="og:title"
                      content={job.title}
                      data-react-helmet="true"
                    />
                    <meta
                      property="og:description"
                      content={job.description}
                      data-react-helmet="true"
                    />

                    <meta
                      property="og:url"
                      content={`https://www.jobmarok.com/${codeLng}/jobs/${job.id}`}
                      data-react-helmet="true"
                    />

                    <meta
                      property="og:image"
                      content={typeof job.image === "string" ? job.image : ""}
                    />
                    <meta
                      property="og:image:url"
                      content={typeof job.image === "string" ? job.image : ""}
                    />
                    <meta property="og:image:width" content="400" />
                    <meta property="og:image:height" content="300" />
                    <meta property="og:image:alt" content={job.title} />

                    <meta name="twitter:title" content={job.title} />
                    <meta
                      name="twitter:description"
                      content={job.description}
                    />
                    <meta
                      name="twitter:image"
                      content={typeof job.image === "string" ? job.image : ""}
                    />
                    <meta name="twitter:image:alt" content={job.title} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <link
                      rel="canonical"
                      href={`https://www.jobmarok.com/${codeLng}/jobs/${job.id}`}
                    />
                  </Helmet>
                </HelmetProvider>
                {userInfo && (
                  <button
                    className="jobMarok__jobDetails-addToMyJob"
                    onClick={() => handleAddJobApplication(job)}
                    style={{
                      color: "#0a66c2",
                      padding: "10px 20px",
                      border: "1px solid #0a66c2",
                      borderRadius: "16px",
                      cursor: "pointer",
                      textTransform: "none",
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    {t("Job Details.Add To my Jobs")}
                  </button>
                )}
                <div className="job-card-info">
                  <div className="jobMarok__job-details_header">
                    <div className="jobMarok__job-details_header-title">
                      <h2 className="job-card-title">{job?.title}</h2>
                      <p className="job-card-company">
                        {job?.company} - {job?.location}
                      </p>
                    </div>
                    <div className="jobMarok__job-details_header-apply">
                      {userInfo ? (
                        <>
                          {job?.phone && job?.phone != "000000000" && (
                            <p className="reduced-description">
                              {t("Job.Phone")}: {job?.phone}
                            </p>
                          )}
                          {job.email && job.email != "Sample Email" && (
                            <p className="reduced-description">
                              {t("Job.Email")}
                              {": "}
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            className="jobMarok__job-details_apply-button"
                            onClick={handleApply}
                            style={{
                              backgroundColor: "#007bff",
                              color: "#fff",
                              padding: "10px 20px",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                              textTransform: "none",
                            }}
                          >
                            {t("Job.Apply Now")}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <hr />
                  <h2 className="jobMarok__job-details">{t("Job.details")}</h2>

                  {job?.type && job.type !== "Sample Type" && (
                    <p className="reduced-description">
                      {t("Job.Job Type")} {job.type}
                    </p>
                  )}

                  <div
                    className="job-description-scroll"
                    dangerouslySetInnerHTML={{
                      __html: job?.description || "",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default JobScreen;
