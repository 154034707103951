import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { getUserDetails, updateUserProfile } from "../../actions/userActions";
import { createJob, listMyJobs } from "../../actions/jobActions";
import { USER_UPDATE_PROFILE_RESET } from "../../constants/userConstants";
import { JOB_CREATE_RESET } from "../../constants/jobConstants";
import { useNavigate } from "react-router-dom";
import { ListMyJobs } from "../../components";
import { CreateMessage } from "../../components/MessageUtils";
import JobHistoryPage from "../../components/jobs/JobHistoryPage";
import { useLanguage } from "../../LanguageContext";
import i18n from "../../i18n";
import "./profile.css";

export type AuthUser = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  bio: string;
  role: "ADMIN" | "USER";
};

export type UserResponse = {
  jwt: string;
  user: AuthUser;
};

interface ParamsKeyword {
  keyword: string;
  page: number;
}

function ProfileScreen() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language, setLanguage } = useLanguage();
  const dir = i18n.language === "ar" ? "rtl" : "ltr";
  const codeLng = window.location.pathname.substring(1, 3) || "fr";

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useDispatch();

  const userDetails = useSelector((state: any) => state.userDetails);
  const { error, loading, user } = userDetails;

  const jobCreate = useSelector((state: any) => state.jobCreate);
  const { success: successCreate, job: createdJob } = jobCreate;

  const userLogin = useSelector((state: any) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector(
    (state: any) => state.userUpdateProfile
  );
  const { success } = userUpdateProfile;

  const searchParams = new URLSearchParams(window.location.search);
  const keyword = searchParams.get("keyword");
  const page = searchParams.get("page");

  const errtMsg = CreateMessage("danger", error);
  const displayMsg = CreateMessage("danger", message);

  useEffect(() => {
    const query: ParamsKeyword = {
      keyword: keyword || "",
      page: Number(page),
    };
    dispatch({ type: JOB_CREATE_RESET });

    if (!userInfo) {
      navigate(`/${codeLng}/login/`);
    } else {
      if (!user || !user.name || success || userInfo.id !== user.id) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch<any>(getUserDetails(userInfo?.id));
        dispatch<any>(listMyJobs(query));
      } else {
        setName(user.name);
        setEmail(user.email);
        setIsChecked(user.is_recruiter);
      }
    }

    if (successCreate) {
      navigate(`/${codeLng}/job/${createdJob.id}/edit`);
    } else {
      dispatch<any>(listMyJobs(query));
    }
  }, [
    dispatch,
    navigate,
    userInfo,
    user,
    success,
    successCreate,
    createdJob,
    keyword,
    codeLng,
    page,
    JobHistoryPage,
  ]);

  const submitHandler = (e: any) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Password do not match");
    } else {
      dispatch<any>(
        updateUserProfile({
          id: user.id,
          name: name,
          email: email,
          password: password,
          isRecruiter: isChecked,
        })
      );
      setMessage("");
    }
  };
  const createJobHandler = () => {
    dispatch<any>(createJob());
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };
  return (
    <div className="jobMarok__profile" dir={dir}>
      <Row>
        <h2>{t("Profile.Profile")}</h2>
        {message && displayMsg}
        {error && errtMsg}
        {loading}
        {loading && <Loader />}
        <Col md={3}>
          <Form onSubmit={submitHandler}>
            <Form.Group
              controlId="switch"
              className="mb-3 jobMarok__Profile-form_input"
            >
              <label className="switch">
                <input
                  className=" jobMarok__Profile-form_input"
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />{" "}
                {t("Register.isRecruiter")}
              </label>
            </Form.Group>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>{t("Profile.Name")}</Form.Label>
              <Form.Control
                required
                type="name"
                placeholder={t("Profile.Name") || ""}
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="jobMarok__Profile-form_input"
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="email" className="mb-3">
              <Form.Label>{t("Profile.Email")}</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder={t("Profile.Email") || ""}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="jobMarok__Profile-form_input"
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="password" className="mb-3">
              <Form.Label>{t("Profile.Password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("Profile.Enter Password") || ""}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="on"
                required
                className="jobMarok__Profile-form_input"
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="confirmPassword" className="mb-3">
              <Form.Label>{t("Profile.Confirm Password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("Profile.Confirm Password") || ""}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                autoComplete="on"
                required
                className="jobMarok__Profile-form_input"
              ></Form.Control>
            </Form.Group>

            <Button
              type="submit"
              className="jobMarok__Profile-button_update"
              variant="primary"
            >
              {t("Profile.Update")}
            </Button>
          </Form>
        </Col>
        <Col md={9}>
          {user?.is_recruiter ? (
            <>
              <Row>
                <Col className="text-right">
                  <Button
                    variant="primary"
                    className="my-3 jobMarok__Profile-button_addJob"
                    onClick={createJobHandler}
                  >
                    <i className="fas fa-plus"></i> {t("Job.Post a Job")}
                  </Button>
                </Col>
              </Row>
              <ListMyJobs keyword={keyword} page={page} />{" "}
            </>
          ) : (
            <JobHistoryPage />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default ProfileScreen;
