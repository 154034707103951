import {
  JOB_CREATE_REQUEST,
  JOB_CREATE_SECCESS,
  JOB_CREATE_FAIL,
  JOB_CREATE_RESET,
  JOB_UPDATE_REQUEST,
  JOB_UPDATE_SECCESS,
  JOB_UPDATE_FAIL,
  JOB_UPDATE_RESET,
  JOB_DETAILS_REQUEST,
  JOB_DETAILS_SECCESS,
  JOB_DETAILS_FAIL,
  JOB_DETAILS_RESET,
  JOB_LIST_MY_REQUEST,
  JOB_LIST_MY_SUCCESS,
  JOB_LIST_MY_FAIL,
  JOB_LIST_MY_RESET,
  JOB_LIST_REQUEST,
  JOB_LIST_SECCESS,
  JOB_LIST_FAIL,
  JOB_DELETE_REQUEST,
  JOB_DELETE_SECCESS,
  JOB_DELETE_FAIL,
} from "../constants/jobConstants";

export const jobCreateReducer = (state = {}, action: any) => {
  switch (action.type) {
    case JOB_CREATE_REQUEST:
      return { loading: true };

    case JOB_CREATE_SECCESS:
      return { loading: false, success: true, job: action.payload };

    case JOB_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case JOB_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const jobDetailsReducer = (
  state = { job: { reviews: [] } },
  action: any
) => {
  switch (action.type) {
    case JOB_DETAILS_REQUEST:
      return { loading: true, ...state };

    case JOB_DETAILS_SECCESS:
      return { loading: false, job: action.payload };

    case JOB_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case JOB_DETAILS_RESET:
      return { job: {} };
    default:
      return state;
  }
};

export const jobUpdateReducer = (state = { job: {} }, action: any) => {
  switch (action.type) {
    case JOB_UPDATE_REQUEST:
      return { loading: true };

    case JOB_UPDATE_SECCESS:
      return { loading: false, success: true, job: action.payload };

    case JOB_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case JOB_UPDATE_RESET:
      return { job: {} };

    default:
      return state;
  }
};

export const jobListReducers = (state = { jobs: [] }, action: any) => {
  switch (action.type) {
    case JOB_LIST_REQUEST:
      return { loading: true, jobs: [] };

    case JOB_LIST_SECCESS:
      return {
        loading: false,
        jobs: action.payload.jobs,
        page: action.payload.page,
        pages: action.payload.pages,
      };

    case JOB_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
// export const bookListMyReducer = (state = { books: [] }, action) => {
//   switch (action.type) {
//     case BOOK_LIST_MY_REQUEST:
//       return {
//         loading: true,
//       };

//     case BOOK_LIST_MY_SUCCESS:
//       return {
//         loading: false,
//         books: action.payload.books,
//       };

//     case BOOK_LIST_MY_FAIL:
//       return {
//         loading: false,
//         error: action.payload,
//       };

//     case BOOK_LIST_MY_RESET:
//       return {
//         books: [],
//       };

//     default:
//       return state;
//   }
// };

export const jobListMyReducer = (state = { jobs: [] }, action: any) => {
  switch (action.type) {
    case JOB_LIST_MY_REQUEST:
      return { loading: true, jobs: [] };

    case JOB_LIST_MY_SUCCESS:
      return {
        loading: false,
        jobs: action.payload.jobs,
        page: action.payload.page,
        pages: action.payload.pages,
      };

    case JOB_LIST_MY_FAIL:
      return { loading: false, error: action.payload };

    case JOB_LIST_MY_RESET:
      return {
        jobs: [],
      };

    default:
      return state;
  }
};

export const jobDeleteReducers = (state = {}, action: any) => {
  switch (action.type) {
    case JOB_DELETE_REQUEST:
      return { loading: true };

    case JOB_DELETE_SECCESS:
      return { loading: false, success: true };

    case JOB_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
