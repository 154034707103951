import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import FormContainer from "../components/FormContainer";
import { resetPasswordConfirm } from "../actions/userActions";
import { useParams, useNavigate } from "react-router-dom";

function ResetPasswordConfirmScreen() {
  const { t } = useTranslation();

  const [new_password, setNew_password] = useState("");
  const [re_new_password, setRe_new_password] = useState("");
  const [requestSent, setRequestSent] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uid = useParams<{ uid: string }>();
  const token = useParams<{ token: string }>();
  const codeLng = window.location.pathname.substring(1, 3) || "fr";

  const submitHandler = (e: any) => {
    e.preventDefault();

    dispatch<any>(
      resetPasswordConfirm(uid, token, new_password, re_new_password)
    );
    setRequestSent(true);
  };
  if (requestSent) {
    setTimeout(() => {
      navigate(`/${codeLng}/`);
    }, 5000);
  }

  return (
    <FormContainer>
      <h1 className="letterSpacing-ar"> {t("Request Password Reset")}</h1>
      {/* {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />} */}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="password" className="mb-3">
          <Form.Label>{t("Password")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("New Password") || ""}
            value={new_password}
            onChange={(e) => setNew_password(e.target.value)}
            minLength={8}
            required
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="password" className="mb-3">
          <Form.Label>{t("Password")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("Confirm Password") || ""}
            value={re_new_password}
            onChange={(e) => setRe_new_password(e.target.value)}
            minLength={8}
            required
          ></Form.Control>
        </Form.Group>

        <Button className="btn btn-primary" type="submit">
          {t("Reset Password")}
        </Button>
      </Form>
    </FormContainer>
  );
}

export default ResetPasswordConfirmScreen;
