import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import axios from "axios";
import { JobApplication } from "../../types";
import "./jobHistoryPage.css";
import { Link } from "react-router-dom";
import { notifySuccess } from "../../components/MessageUtils";
import { ToastContainer } from "react-toastify";

const JobHistoryPage: React.FC = () => {
  const { t } = useTranslation();
  const userLogin = useSelector((state: any) => state.userLogin);
  const { userInfo } = userLogin;
  const [jobApplications, setJobApplications] = useState<JobApplication[]>([]);
  const codeLng = window.location.pathname.substring(1, 3) || "fr";

  const fetchJobApplications = useCallback(async () => {
    try {
      const axiosConfig = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
        params: { user: userInfo },
      };
      const response = await axios.get<JobApplication[]>(
        "/api/jobs/job-applications",
        axiosConfig
      );
      setJobApplications(response.data);
    } catch (error) {
      console.error("Error fetching job applications:", error);
    }
  }, [userInfo]);

  useEffect(() => {
    fetchJobApplications();
  }, [fetchJobApplications]);

  const handleDelete = async (id: number) => {
    try {
      const axiosConfig = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(`/api/jobs/job-applications/${id}/`, axiosConfig);
      notifySuccess("Job deleted successfully!");
      fetchJobApplications();
    } catch (error) {
      console.error("Error deleting job application:", error);
    }
  };

  const handleStatusUpdate = async (id: number, newStatus: string) => {
    try {
      const axiosConfig = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.put(
        `/api/jobs/job-applications/${id}/`,
        { status: newStatus },
        axiosConfig
      );
      notifySuccess("Job updated successfully!");
      fetchJobApplications();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="job-history-container">
        <h1>{t("Job.Job Applications History")}</h1>
        <br />
        <div className="job-table-container">
          <div className="job-history-container-table-header">
            <div className="job-history-container-table-cell">
              {t("Job.Company")}
            </div>
            <div className="job-history-container-table-cell">
              {t("Job.Position")}
            </div>
            <div className="job-history-container-table-cell">
              {t("Job.Location")}
            </div>
            <div className="job-history-container-table-cell">
              {t("Job.Date Applied")}
            </div>
            <div className="job-history-container-table-cell">
              {t("Job.Status")}
            </div>
            <div className="job-history-container-table-cell">
              {t("Job.Actions")}
            </div>
          </div>

          {jobApplications.map((application) => (
            <div
              key={application.id}
              className="job-history-container-table-row"
            >
              <div className="job-history-container-table-cell">
                {application.company}
              </div>
              <div className="job-history-container-table-cell">
                <Link to={`/${codeLng}/jobs/${application.job}`}>
                  {application.title}
                </Link>
              </div>
              <div className="job-history-container-table-cell">
                {application.location}
              </div>
              <div className="job-history-container-table-cell">
                {application.date_applied}
              </div>

              <div className="job-history-container-table-cell">
                <select
                  value={application.status}
                  onChange={(e) =>
                    handleStatusUpdate(application.id, e.target.value)
                  }
                >
                  <option value="applied">{t("Job.Applied")}</option>
                  <option value="interviewed">{t("Job.Interviewed")}</option>
                  <option value="rejected">{t("Job.Rejected")}</option>
                  <option value="hired">{t("Job.Hired")}</option>
                </select>
              </div>

              <div className="job-history-container-table-cell">
                <button onClick={() => handleDelete(application.id)}>
                  {t("Job.Delete")}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default JobHistoryPage;
