import { Outlet, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const PrivateRoutes = () => {
  const { t, i18n } = useTranslation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return userInfo ? <Outlet /> : <Navigate to={`/${i18n.language}/login`} />;
};

export default PrivateRoutes;
