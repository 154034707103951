import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export function CreateMessage(param1: string, param2: string): JSX.Element {
  const { t, i18n } = useTranslation();
  return <Alert variant={param1}>{t("Messages." + param2)}</Alert>;
}

export const notifyErr = (msg: string) =>
  toast.error(msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
export const notifySuccess = (msg: string) =>
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
