import React from "react";
import jobmarok_tb from "../../assets/jobmarok_tb.png";
import "./job.css";
import "react-quill/dist/quill.snow.css";
import { Job as JobeType } from "../../types";

export interface JobProps {
  job: any;
}

const Job: React.FC<JobProps> = ({ job }) => {
  return (
    <div className="job-list">
      <div className={"job-card"}>
        <img className="job-card-image " src={job.image} alt={job.title} />
        <div className="job-card-info">
          <h2 className="job-card-title">{job.title}</h2>
          <p className="job-card-location">{job.location}</p>
          <p className="job-card-company">{job.company}</p>
          <p
            dangerouslySetInnerHTML={{
              __html: job?.description.slice(0, 135) || "",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Job;
