import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import "./about.css";

const AboutScreen = () => {
  const { t } = useTranslation();

  const dir = i18n.language === "ar" ? "rtl" : "ltr";

  const aboutEn = ` <h1>About Us</h1>
  <p>Welcome to JobMarok! Your one-stop destination for finding the best job opportunities in Morocco. We understand that finding the right job can be a transformative experience, shaping your career and future. That's why we've built JobMarok with a mission to connect job seekers with the perfect job opportunities that match their skills, passions, and aspirations.</p>

  <h2>Our Vision</h2>
  <p>At JobMarok, our vision is to empower individuals by providing them with access to a wide range of job listings across various sectors and industries. We aim to be the bridge between employers and job seekers, creating a platform where talent meets opportunity. Our platform is designed to streamline the job search process, making it easier and more efficient for both job seekers and employers to find their ideal matches.</p>

  <h2>What We Offer</h2>
  <ul>
      <li><strong>Diverse Job Listings:</strong> We curate and list job opportunities from diverse industries, helping job seekers explore options that align with their interests and expertise.</li>
      <li><strong>User-Friendly Experience:</strong> Our intuitive platform ensures a seamless and user-friendly experience for job seekers to search, filter, and apply for jobs.</li>
      <li><strong>Employer Partnerships:</strong> We collaborate with reputable companies and organizations, ensuring that job seekers have access to quality job listings.</li>
      <li><strong>Career Resources:</strong> We provide valuable career resources, tips, and advice to assist job seekers in their journey toward professional success.</li>
  </ul>

  <h2>Our Team</h2>
  <p>Behind JobMarok is a dedicated team of professionals who are passionate about helping individuals achieve their career goals. With a blend of expertise in technology, recruitment, and user experience, we are committed to continuously enhancing our platform to better serve job seekers and employers alike.</p>

  <h2>Contact Us</h2>
  <p>If you have any questions, suggestions, or feedback, we'd love to hear from you. Feel free to reach out to our team at <a href="mailto:jobmarok7@gmail.com">jobmarok7@gmail.com</a>.</p>

  <p>Thank you for choosing JobMarok. Together, let's navigate the path to your dream job!</p>`;

  const aboutAr = `
  <h1>من نحن</h1>
  <p>مرحبًا بك في JobMarok! وجهتك الشاملة للعثور على أفضل فرص الوظائف في المغرب. نحن ندرك أن العثور على الوظيفة المناسبة يمكن أن يكون تجربة محورية تشكل مستقبلك المهني. لهذا السبب قمنا ببناء JobMarok بهدف ربط الباحثين عن وظائف بفرص الوظائف المثالية التي تتناسب مع مهاراتهم واهتماماتهم وطموحاتهم.</p>

  <h2>رؤيتنا</h2>
  <p>في JobMarok، رؤيتنا هي تمكين الأفراد من خلال توفير وصول لهم إلى مجموعة واسعة من قوائم الوظائف عبر مختلف القطاعات والصناعات. نهدف إلى أن نكون الجسر بين أصحاب العمل والباحثين عن وظائف، من خلق منصة حيث يلتقي الموهوبون بالفرص. تم تصميم منصتنا لتبسيط عملية البحث عن وظائف، مما يجعلها أسهل وأكثر كفاءة لكل من الباحثين عن وظائف وأصحاب العمل للعثور على التوافق المثالي.</p>

  <h2>ما نقدمه</h2>
  <ul>
    <li><strong>قوائم وظائف متنوعة:</strong> نقوم بتنسيق وعرض فرص الوظائف من صناعات متنوعة، مما يساعد الباحثين عن وظائف على استكشاف الخيارات التي تتوافق مع اهتماماتهم وخبراتهم.</li>
    <li><strong>تجربة سهلة الاستخدام:</strong> منصتنا البديهية تضمن تجربة سلسة وسهلة للباحثين عن وظائف للبحث وتصفية وتقديم طلبات الوظائف.</li>
    <li><strong>شراكات مع أصحاب العمل:</strong> نتعاون مع الشركات والمؤسسات الموثوقة، مما يضمن للباحثين عن وظائف الوصول إلى قوائم وظائف عالية الجودة.</li>
    <li><strong>موارد مهنية:</strong> نقدم موارد مهنية قيمة، نصائح ومشورة لمساعدة الباحثين عن وظائف في رحلتهم نحو النجاح المهني.</li>
  </ul>

  <h2>فريقنا</h2>
  <p>وراء JobMarok، هناك فريق مكرس من المحترفين الذين يهتمون بمساعدة الأفراد على تحقيق أهدافهم المهنية. بفضل مزيج من الخبرة في التكنولوجيا والتوظيف وتجربة المستخدم، نحن ملتزمون بتحسين منصتنا بشكل مستمر لخدمة الباحثين عن وظائف وأصحاب العمل على حد سواء.</p>

  <h2>اتصل بنا</h2>
  <p>إذا كان لديك أي أسئلة أو اقتراحات أو ملاحظات، يسعدنا أن نسمع منك. لا تتردد في التواصل مع فريقنا على البريد الإلكتروني <a href="mailto:jobmarok7@gmail.com">jobmarok7@gmail.com</a>.</p>

  <p>شكرًا لاختيارك JobMarok. دعونا نسوق معًا نحو طريق الوظيفة المثالية!</p>
`;
  const aboutFr = `<h1>À Propos</h1>
  <p>Bienvenue sur JobMarok ! Votre destination unique pour trouver les meilleures opportunités d'emploi au Maroc. Nous comprenons que trouver le bon emploi peut être une expérience transformative, façonnant votre carrière et votre avenir. C'est pourquoi nous avons créé JobMarok avec pour mission de connecter les demandeurs d'emploi aux opportunités d'emploi parfaites qui correspondent à leurs compétences, passions et aspirations.</p>
  
  <h2>Notre Vision</h2>
  <p>Chez JobMarok, notre vision est d'autonomiser les individus en leur fournissant un accès à un large éventail d'offres d'emploi dans différents secteurs et industries. Nous visons à être le pont entre les employeurs et les demandeurs d'emploi, créant une plateforme où le talent rencontre l'opportunité. Notre plateforme est conçue pour rationaliser le processus de recherche d'emploi, rendant plus facile et plus efficace pour les demandeurs d'emploi et les employeurs de trouver leurs correspondances idéales.</p>
  
  <h2>Notre Offre</h2>
  <ul>
      <li><strong>Offres d'emploi Diversifiées :</strong> Nous sélectionnons et répertorions des opportunités d'emploi de diverses industries, aidant les demandeurs d'emploi à explorer des options en accord avec leurs intérêts et leur expertise.</li>
      <li><strong>Expérience Conviviale :</strong> Notre plateforme intuitive garantit une expérience fluide et conviviale pour les demandeurs d'emploi afin de rechercher, filtrer et postuler à des emplois.</li>
      <li><strong>Partenariats avec les Employeurs :</strong> Nous collaborons avec des entreprises et organisations de confiance, assurant aux demandeurs d'emploi un accès à des offres d'emploi de qualité.</li>
      <li><strong>Ressources de Carrière :</strong> Nous fournissons des ressources de carrière précieuses, des conseils et des conseils pour aider les demandeurs d'emploi dans leur parcours vers le succès professionnel.</li>
  </ul>
  
  <h2>Notre Équipe</h2>
  <p>Derrière JobMarok se trouve une équipe dévouée de professionnels passionnés par l'aide aux individus à atteindre leurs objectifs de carrière. Avec un mélange d'expertise en technologie, recrutement et expérience utilisateur, nous nous engageons à améliorer continuellement notre plateforme pour mieux servir les demandeurs d'emploi et les employeurs.</p>
  
  <h2>Contactez-Nous</h2>
  <p>Si vous avez des questions, des suggestions ou des commentaires, nous serions ravis d'avoir de vos nouvelles. N'hésitez pas à contacter notre équipe à l'adresse <a href="mailto:jobmarok7@gmail.com">jobmarok7@gmail.com</a>.</p>
  
  <p>Merci d'avoir choisi JobMarok. Ensemble, naviguons le chemin vers votre emploi de rêve !</p>
  `;

  return (
    <div className="about-screen" dir={dir}>
      <section
        className="about-section"
        dangerouslySetInnerHTML={{
          __html:
            i18n.language === "ar"
              ? aboutAr
              : i18n.language === "en"
              ? aboutEn
              : aboutFr,
        }}
      />
    </div>
  );
};

export default AboutScreen;
