export const JOB_CREATE_REQUEST = "JOB_CREATE_REQUEST";
export const JOB_CREATE_SECCESS = "JOB_CREATE_SECCESS";
export const JOB_CREATE_FAIL = "JOB_CREATE_FAIL";
export const JOB_CREATE_RESET = "JOB_CREATE_RESET";

export const JOB_LIST_MY_REQUEST = "JOB_LIST_MY_REQUEST";
export const JOB_LIST_MY_SUCCESS = "JOB_LIST_MY_SUCCESS";
export const JOB_LIST_MY_FAIL = "JOB_LIST_MY_FAIL";
export const JOB_LIST_MY_RESET = "JOB_LIST_MY_RESET";

export const JOB_UPDATE_REQUEST = "JOB_UPDATE_REQUEST";
export const JOB_UPDATE_SECCESS = "JOB_UPDATE_SECCESS";
export const JOB_UPDATE_FAIL = "JOB_UPDATE_FAIL";
export const JOB_UPDATE_RESET = "JOB_UPDATE_RESET";

export const JOB_DETAILS_REQUEST = "JOB_DETAILS_REQUEST";
export const JOB_DETAILS_SECCESS = "JOB_DETAILS_SECCESS";
export const JOB_DETAILS_FAIL = "JOB_DETAILS_FAIL";
export const JOB_DETAILS_RESET = "JOB_DETAILS_RESET";

export const JOB_DELETE_REQUEST = "JOB_DELETE_REQUEST";
export const JOB_DELETE_SECCESS = "JOB_DELETE_SECCESS";
export const JOB_DELETE_FAIL = "JOB_DELETE_FAIL";
export const JOB_DELETE_RESET = "JOB_DELETE_RESET";

export const JOB_LIST_REQUEST = "JOB_LIST_REQUEST";
export const JOB_LIST_SECCESS = "JOB_LIST_SECCESS";
export const JOB_LIST_FAIL = "JOB_LIST_FAIL";
