import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "./LanguageContext";

const RedirectToDefaultLanguage = () => {
  const navigate = useNavigate();
  const { language, setLanguage } = useLanguage();

  useEffect(() => {
    navigate(`/${language}/`);
  }, [navigate]);

  return null;
};

export default RedirectToDefaultLanguage;
