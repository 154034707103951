import React, { useState, useEffect } from "react";
import "./form.css";
import { GoSearch, GoLocation } from "react-icons/go";
import { GrFormClose } from "react-icons/gr";
import { useDispatch } from "react-redux";
import { listJobs } from "../../actions/jobActions";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../LanguageContext";
import i18n from "../../i18n";

interface Props {
  initSelectedJob: () => void;
}

const SearchBox: React.FC<Props> = ({ initSelectedJob }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [jobTitle, setJobTitle] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const { language, setLanguage } = useLanguage();
  const dir = i18n.language === "ar" ? "rtl" : "ltr";

  useEffect(() => {}, [jobTitle]);

  let page = "";
  const handleSearch = (e: any) => {
    e.preventDefault();
    initSelectedJob();
    if (jobTitle || jobLocation) {
      dispatch<any>(listJobs(jobTitle, jobLocation, page));
    } else {
      dispatch<any>(listJobs(jobTitle, jobLocation, page));
    }
  };

  const clearJobTitle = () => {
    setJobTitle("");

    dispatch<any>(listJobs("", jobLocation, page));
  };
  const clearJobLocation = () => {
    setJobLocation("");
    dispatch<any>(listJobs(jobTitle, "", page));
  };
  return (
    <div className="search-container">
      <form className="search-container" onSubmit={handleSearch}>
        <div className="search-container_job">
          <GoSearch className="search-container_jobTitle-icon" />
          <input
            dir={dir}
            className="search-input"
            type="text"
            value={jobTitle}
            onChange={(event) => setJobTitle(event.target.value)}
            placeholder={t("Search.keyword") || ""}
          />

          {jobTitle ? (
            <GrFormClose
              onClick={clearJobTitle}
              className="search-container_jobTitle-icon"
            />
          ) : (
            ""
          )}
        </div>
        <div className="search-container_location">
          <GoLocation className="search-container_location-icon" />
          <input
            dir={dir}
            className="search-input"
            type="text"
            value={jobLocation}
            onChange={(event) => setJobLocation(event.target.value)}
            placeholder={t("Search.region") || ""}
          />
          {jobLocation ? (
            <GrFormClose
              onClick={clearJobLocation}
              className="search-container_location-icon"
            />
          ) : (
            ""
          )}
        </div>
        <button className="search-button" type="submit">
          {t("Search.Find jobs")}
        </button>
      </form>
    </div>
  );
};

export default SearchBox;
